import React, { useState, useEffect } from "react";
import "./SigninForm.css";
import "./SigninFormAnimation.css";
import "../Form.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import loginSound from "../../../Audio/createGame.mp3";
import SigninCompletedFeatures from "../Signin/SigninCompletedFeatures";
import { Link, useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SigninPlace

function SigninForm({ userLoginAsync, isShowGuestAsync, authStateCondition }) {
  const { user } = authStateCondition;

  const navigate = useNavigate();

  const [loggedInUser, setLoggedInUser] = useState(false);

  // if user has already logged in, make unclickable
  useEffect(() => {
    if (user !== undefined && user !== null) {
      setLoggedInUser(true);
    } else {
      setLoggedInUser(false);
    }
  }, [user]);
  // if user has already logged in, make unclickable

  // hide signin form, when already filled out form and pushed "log in" button
  const [signinFinished, setSigninFinished] = useState("signin");

  const [hidden, setHidden] = useState("signin_content");

  // hide signin form, when already filled out form and pushed "log in" button

  // Form error messages
  const [errForm, setErrForm] = useState({
    email_err_display: "hidden",
    password_err_display: "hidden",
  });

  // Distracture Form error messages
  const { email_err_display, password_err_display } = errForm;
  // Form error messages

  // Form Data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // Distracture Form Data
  const { email, password } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  // Get Form Values

  // Handle Login
  const handleLogin = async (email, password) => {
    const userCredentials = {
      email: email,
      password: password,
    };

    const authSuccess = await userLoginAsync(userCredentials);

    if (authSuccess) {
      // console.log('Im here');

      isShowGuestAsync(false);
    } else {
      console.log("wrong credentials");
    }
  };
  // Handle Login

  // Submit Form
  const onSubmit = (e) => {
    e.preventDefault();

    //play click
    ClickSound(loginSound);

    // validate username, email and password fields
    const validSymbols = /^[a-zA-Z0-9]+$/;
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // validate username, email and password fields

    // temporary errors in a function, at the end of function assign to useState
    const tempErrForm = {
      ...errForm,
    };
    // temporary errors in a function, at the end of function assign to useState

    // temporary user data, check if all fields are good to send to back side
    const temLoggedUser = {
      tempLoggedEmail: "",
      tempLoggedPassword: "",
    };
    // temporary user data, check if all fields are good to send to back side

    // email validation
    if (email.length < 6 || email.length > 32 || !email.match(validEmail)) {
      Object.assign(tempErrForm, {
        email_err_display: "email_err_display",
      });

      Object.assign(temLoggedUser, {
        tempLoggedEmail: null,
      });
    } else {
      Object.assign(tempErrForm, {
        email_err_display: "hidden",
      });

      Object.assign(temLoggedUser, {
        tempLoggedEmail: email,
      });
    }
    // email validation

    // password validation
    if (
      password.length < 6 ||
      password.length > 16 ||
      !password.match(validSymbols)
    ) {
      Object.assign(tempErrForm, {
        password_err_display: "password_err_display",
      });

      Object.assign(temLoggedUser, {
        tempLoggedPassword: null,
      });
    } else {
      Object.assign(tempErrForm, {
        password_err_display: "hidden",
      });

      Object.assign(temLoggedUser, {
        tempLoggedPassword: password,
      });
    }
    // password validation

    // errors send to usestate to control classes //login
    setErrForm(tempErrForm);
    // errors send to usestate to control classes //login

    // temporary user object values while log in
    const checkEmail = Object.values(temLoggedUser)[0];
    const checkPassword = Object.values(temLoggedUser)[1];
    // temporary user object values while log in

    // temporary user object values, to check if all fields are ok to send to back side while log in
    if (checkEmail !== null || checkPassword !== null) {
      if (user === null && !loggedInUser) {
        handleLogin(checkEmail, checkPassword);

        setSigninFinished("signin_finished");

        setHidden("hidden");
      } else {
        navigate("/profile");
      }
    }
    // temporary user object values, to check if all fields are ok to send to back side while log in
  };
  // Submit Form

  // handle Forget password and Registration clicks, just for sound
  const handleForgetAndRegister = () => {
    //play click
    ClickSound(popUpSound);
  };
  // handle Forget password and Registration clicks, just for sound

  return (
    <section className={signinFinished}>
      <SigninCompletedFeatures />

      <div className={hidden}>
        <h2>Sign in</h2>
        <div className="form-err">
          {/* error messages */}
          <div className={email_err_display}>
            <p>!!! Please enter a valid email address.</p>
          </div>
          <div className={password_err_display}>
            <p>
              !!! The password should be between 6 and 16 characters long.
              Numbers and letters should be used only. Symbols are not allowed.
            </p>
          </div>
          {/* error messages */}
        </div>
        {/*Form */}
        <form
          className={!loggedInUser ? "form" : "form disable-link"}
          onSubmit={onSubmit}
        >
          <div className="inputBox">
            <input
              type="email"
              name="email"
              id="signin-email"
              value={email}
              onChange={onChange}
              required
              maxLength="32"
              title="Log in if you already have a registered email address."
              autoFocus
              autoComplete="email"
            />
            <i>E-mail</i>
          </div>
          <div className="inputBox">
            <input
              type="password"
              name="password"
              id="signin-password"
              value={password}
              onChange={onChange}
              required
              maxLength="16"
              title="Type the current password."
              autoComplete="off"
            />
            <i>Password</i>
          </div>
          <div
            className="input_links"
            title="Click here if you forgot your password"
          >
            <Link to="/forget-password" onClick={handleForgetAndRegister}>
              Forget Password?
            </Link>
          </div>
          <div
            className="inputBox"
            title="If everything checks out, just log in!"
          >
            <button className="submit_BTN" type="submit">
              Log in
            </button>
          </div>
          <div
            className="input_links"
            title="Join now if you don't have an account!"
          >
            <p>Don&apos;t have an account?</p>
            <Link to="/join" onClick={handleForgetAndRegister}>
              Registration
            </Link>
          </div>
        </form>
        {/*Form */}
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
