import React, { useRef, useEffect } from "react";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../State/Store/storeProps";
import { connect } from "react-redux";

const SoundCountDownTimer = ({
  Audiodata,
  delay = 3000,
  mutedMusicCondition,
}) => {
  // Add delay prop, default to 1000ms

  const [timerSound, isPageRefreshed, isDirectAccess, isExternalNavigation] =
    Audiodata;
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    let timer;

    // Check if any of the conditions are true
    if (
      isPageRefreshed ||
      isPageRefreshed === undefined ||
      isDirectAccess ||
      isDirectAccess === undefined ||
      isExternalNavigation ||
      isExternalNavigation === undefined
    ) {
      // Skip playing the sound

      return;
    }

    if (audio) {
      timer = setTimeout(() => {
        audio.play().catch((err) => console.log(err));
        // Mute or unmute the audio based on mutedMusicCondition
        audio.volume = mutedMusicCondition ? 0 : 1;
      }, delay); // Use the delay prop here
    }

    return () => {
      clearTimeout(timer); // Clear the timeout on cleanup
      if (audio) {
        audio.pause();
      }
    };
  }, [delay, mutedMusicCondition]);

  return (
    <audio ref={audioRef} loop>
      <source src={timerSound} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SoundCountDownTimer);
