import React from "react";
import "./GuestArea.css";
import "./GuestAreaAnimation.css";
import guestMusic from "../../../Audio/guestMusic.mp3";
import GuestBackGroundMusic from "../../MusicAndSounds/GuestBackGroundMusic";
import LoaderArrow from "../../Loading/LoaderArrow";
import GuestButtons from "./GuestButtons";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - General

function GuestCardArea({ showGuestCondition, hamburgerCondition }) {
  const guestWelcomingText = "Welcome Guest !!!";
  const guestheader = "The limitations";
  const guestText =
    "Thanks for joining us. Guest users cannot play, but they can still watch the games, so don't worry. Log in if you want to play and join the fun! Whether you're here to play or just enjoy the games, we're glad you're a part of the action. Let the games begin!";

  return (
    <section className="GuestCardArea">
      <GuestBackGroundMusic
        Audiodata={[guestMusic, showGuestCondition, hamburgerCondition]}
      />

      <div className="guest_card-container">
        <div className="guest_card">
          <div className="guest_front-content">
            <p>{guestWelcomingText}</p>

            <LoaderArrow />
          </div>
          <div
            className="guest_content"
            title="Guests can watch games but cannot play."
          >
            <p className="guest_heading">{guestheader}</p>
            <p>{guestText}</p>

            <GuestButtons />
          </div>
        </div>
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestCardArea);
