import React from "react";
import "./GeneralImagePlace.css";

// parent component is - General

function MainImage() {
  return <div className="MainImage"></div>;
}

export default MainImage;
