const SHOWGUESTSECTION = "SHOWGUESTSECTION";

const isShowGuest = (check) => {
  return {
    type: SHOWGUESTSECTION,
    payload: check,
  };
};

export const isShowGuestAsync = (check) => (dispatch) => {
  dispatch(isShowGuest(check));
};
