import React from "react";
import "./MenuButtons.css";
import "./MenuButtonsAnimation.css";
import SingleUserButton from "./SingleUserButton";
import MultiPlayerButton from "./MultiPlayerButton";
import TournamentsButton from "./TournamentsButton";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - General

function AllButtons({ authStateCondition }) {
  // Data is transfering to child components
  const { user } = authStateCondition;
  // Data is transfering to child components

  return (
    <section className="AllButtons">
      <SingleUserButton user={user} />

      <MultiPlayerButton user={user} />

      <TournamentsButton user={user} />
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllButtons);
