import React from "react";
import "./GameWatchingLimitations.css";
import "./GameWatchingLimitationsAnimation.css";
import GuestButtons from "../../../../General/Guest/GuestButtons";

// parent component is - GameWatchingContainer

function GameWatchingLimitations() {
  const guestheader = "The limitations";
  const guestText =
    "Thanks for joining us. Guest users cannot play, but they can still watch the games, so don't worry. Log in if you want to play and join the fun! Whether you're here to play or just enjoy the games, we're glad you're a part of the action. Let the games begin!";

  return (
    <div
      className="GameWatchingContainer-limitations"
      title="Guests can watch games but cannot play."
    >
      <p className="GameWatchingContainer-limitations_heading">{guestheader}</p>
      <p className="GameWatchingContainer-limitations_text">{guestText}</p>

      <GuestButtons />
    </div>
  );
}

export default GameWatchingLimitations;
