import React, { useState, useEffect } from "react";
import "./GuestArea.css";
import "./GuestAreaAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import popUpSound from "../../../Audio/popUp.mp3";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - GuestArea

function GuestButtons({ multiPlayerCondition, tournamentCondition }) {
  const navigate = useNavigate();
  let location = useLocation();
  let exactLocation = location.pathname.split("/").length;

  const [switchTypes, setSwitchTypes] = useState(undefined);
  const [roomsOrcontinue, setRoomsOrcontinue] = useState("");

  useEffect(() => {
    if (exactLocation === 2) {
      setRoomsOrcontinue("Continue");
    } else if (exactLocation === 4) {
      setRoomsOrcontinue("Rooms");
    } else {
      setRoomsOrcontinue("Continue");
    }
  }, [exactLocation]);

  useEffect(() => {
    if (tournamentCondition === true) {
      setSwitchTypes("/tournaments");
    } else if (multiPlayerCondition === true) {
      setSwitchTypes("/multi-player");
    } else {
      setSwitchTypes("/single-player");
    }
  }, [multiPlayerCondition, tournamentCondition]);

  const handleClick = (e) => {
    const btn = e.target.name;

    if (btn === "login") {
      //play click login
      ClickSound(popUpSound);

      navigate("/login");
    } else if (btn === "Continue") {
      //play click continue
      ClickSound(menuButtonsSound);

      navigate(`${switchTypes}`);
    } else if (btn === "Rooms") {
      //play click continue
      ClickSound(menuButtonsSound);

      navigate("/single-player");
    }
  };

  return (
    <div className="GuestButtons">
      <button
        className="guestButtons_button guestlog"
        name="login"
        onClick={handleClick}
        title="If you want to play, please log in"
      >
        <div className="guestButtons_text">Log in</div>
      </button>
      <button
        className="guestButtons_button"
        name={roomsOrcontinue}
        onClick={handleClick}
        title="Guests cannot play, but can watch others game"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
          ></path>
        </svg>
        <div className="guestButtons_text">{roomsOrcontinue}</div>
      </button>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestButtons);
