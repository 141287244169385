import React, { useRef, useEffect } from "react";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../State/Store/storeProps";
import { connect } from "react-redux";

const GuestBackGroundMusic = ({ Audiodata, mutedMusicCondition }) => {
  const [guestMusic, showGuestCondition, hamburgerCondition] = Audiodata;

  const audioRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      audio.currentTime = 0;
    };

    if (showGuestCondition && !hamburgerCondition) {
      timeoutRef.current = setTimeout(() => {
        if (audio) {
          audio.currentTime = 0; // Ensure the audio is reset
          audio.play().catch((err) => console.log(err));
          audio.addEventListener("ended", handleEnded);
        }
      }, 3000);
    } else {
      if (audio) {
        audio.pause();
        audio.currentTime = 0; // Ensure the audio is reset
        audio.removeEventListener("ended", handleEnded);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear any pending timeout
      }
    }

    return () => {
      if (audio) {
        audio.pause();
        audio.removeEventListener("ended", handleEnded);
        // Mute or unmute the audio based on mutedMusicCondition
        audio.volume = mutedMusicCondition ? 0 : 1;
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [showGuestCondition, hamburgerCondition, mutedMusicCondition]);

  return (
    <audio ref={audioRef} loop>
      <source src={guestMusic} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuestBackGroundMusic);
