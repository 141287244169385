import React, { useEffect, useState } from "react";
import "./RoomGameNumbers.css";
import "./RoomGameNumbersAnimation.css";
import roomNumbersBackgroundSound from "../../../../Audio/roomNumbersBackground.mp3";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import LoaderCircleNumbers from "../../../Loading/LoaderCircleNumbers";
import GameScore from "./GameScore/GameScore";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import WaitingForOtherPlayers from "./WaitingForOtherPlayers/WaitingForOtherPlayers";
import GameStatus from "./GameStatus/GameStatus";
import Digits from "./Digts/Digits";
import OpponentTurnPause from "./OpponentTurnPause/OpponentTurnPause";
import OpponentTurnPauseSecondLayer from "./OpponentTurnPause/OpponentTurnPauseSecondLayer";
import MileStones from "./MileStones/MileStones";
import SupportOasis from "./SupportOasis/SupportOasis";
import RoomGameSettingsAndExitBtns from "./RoomGameSettingsAndExitBtns";
import ExitRoomConfirm from "../ExitRoomConfirm/ExitRoomConfirm";
import GetTimedate from "../CountdownTimer/GetTimedate";
import Explanation from "./Explanation/Explanation";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SingleRoom

function RoomGameNumbers({
  roomData,
  isExitRoomAsync,
  editRoomAsync,
  isWatchingGameCondition,
  oasisHelpCondition,
  isOasisHelpClickedAsync,
  counDownCondition,
  isCountDownTimerZeroAsync,
  hamburgerCondition,
  exitRoomCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  // roomData is coming from parent component - SingleRoom
  const [
    roomNumber,
    roomId,
    userId,
    userName,
    exactLocation,
    thisRoomPlayersCount,
    ,
    thisRoomPlayerIds,
    thisRoomWhosTurn,
    thisRoom,
    user,
  ] = roomData;
  // roomData is coming from parent component - SingleRoom

  // for timer / in seconds
  const differenceSeconds = GetTimedate();
  // for timer / in seconds

  const [hidden, setHidden] = useState(true);

  const [myDigit, setMyDigit] = useState(null);

  const [hidenGameScore, setHidenGameScore] = useState("hidden");

  const indexOfPlayer =
    thisRoomPlayerIds !== undefined
      ? thisRoomPlayerIds.indexOf(userId)
      : undefined;

  const gameStarted =
    thisRoomWhosTurn !== undefined ? thisRoomWhosTurn.gameStarted : undefined;

  useEffect(() => {
    if (thisRoom !== undefined) {
      if (
        (user === null && user === undefined && user === "") ||
        thisRoomWhosTurn.gameOver
      ) {
        setHidden(true);
      } else {
        if (
          thisRoomPlayerIds.includes(userId) ||
          isWatchingGameCondition === "play"
        ) {
          setHidden(false);
        } else {
          setHidden(true);
        }
      }
    }
  }, [
    user,
    isWatchingGameCondition,
    userId,
    thisRoom,
    thisRoomPlayerIds,
    thisRoomWhosTurn,
  ]);

  const [insideHide, setInsideHide] = useState({
    features: "hidden",
    circle: "hidden",
    help: "hidden",
    rounds: "hidden",
  });

  // hide "countdown" and "digits" when there is not enough players
  const [hideCountDown, setHideCountDown] = useState("hidden");
  const [hideDigits, setHideDigits] = useState(false);
  // hide "countdown" and "digits" when there is not enough players

  const [playersOrder, setplayersOrder] = useState("Player1");

  useEffect(() => {
    if (indexOfPlayer === 0) {
      setplayersOrder("player1");
    } else if (indexOfPlayer === 1) {
      setplayersOrder("player2");
    } else if (indexOfPlayer === 2) {
      setplayersOrder("player3");
    } else if (indexOfPlayer === 3) {
      setplayersOrder("player4");
    } else {
      setplayersOrder("watcher");
    }
  }, [indexOfPlayer]);

  const [yourTurnShow, setYourTurnShow] = useState(false);

  const [yourTurnShowQuick, setYourTurnShowQuick] = useState(false);

  const [disableClick, setDisableClick] = useState("circle-numbers");

  const [disableLink, setDisableLink] = useState("RoomGameNumbersHelp");

  useEffect(() => {
    if (playersOrder === "watcher") {
      setYourTurnShow(false);

      setYourTurnShowQuick(false);
    } else {
      if (
        thisRoomWhosTurn !== undefined &&
        thisRoomWhosTurn[playersOrder].turn
      ) {
        setYourTurnShow(true);

        setYourTurnShowQuick(false);

        setDisableClick("circle-numbers");

        setDisableLink("RoomGameNumbersHelp");

        // show game score if current number is greater than 0
        if (thisRoomWhosTurn.currentNumber > 0) {
          setHidenGameScore("RoomGameNumbers-GameScore");
        } else {
          setHidenGameScore("hidden");
        }
      } else {
        setYourTurnShow(false);

        setDisableClick("circle-numbers disable");

        setDisableLink("RoomGameNumbersHelp disable-link");

        setHidenGameScore("hidden");
      }
    }
  }, [thisRoomWhosTurn, playersOrder]);

  const { features, circle, help } = insideHide;

  // check if there are enough players to start the game
  useEffect(() => {
    if (exactLocation === "single-player" && thisRoomPlayersCount === 2) {
      setInsideHide({
        features: "RoomGameNumbersFeaturesContainer",
        circle: "circle-numbers-container",
        help: "RoomGameNumbersHelpContainer",
        rounds: "RoomGameNumbers-Rounds",
      });

      setHideCountDown("CountdownTimer");
      setHideDigits(true);
    } else if (exactLocation === "multi-player" && thisRoomPlayersCount === 4) {
      setInsideHide({
        features: "RoomGameNumbersFeaturesContainer",
        circle: "circle-numbers-container",
        help: "RoomGameNumbersHelpContainer",
        rounds: "RoomGameNumbers-Rounds",
      });

      setHideCountDown("CountdownTimer");
      setHideDigits(true);
    } else {
      setInsideHide({
        features: "hidden",
        circle: "hidden",
        help: "hidden",
        rounds: "hidden",
      });

      setHideCountDown("hidden");
      setHideDigits(false);
    }
  }, [thisRoom, exactLocation, roomId, thisRoomPlayersCount]);
  // check if there are enough players to start the game

  useEffect(() => {
    if (counDownCondition) {
      setDisableClick("circle-numbers disable");

      setDisableLink("RoomGameNumbersHelp disable-link");
    } else {
      setDisableClick("circle-numbers");

      setDisableLink("RoomGameNumbersHelp");
    }
  }, [counDownCondition]);

  // you guessed the number also
  const [youAreGuessed, setYouAreGuesed] = useState(false);
  // you guessed the number also

  // clicked circle number
  const onNumberClick = (e = {}) => {
    setDisableClick("circle-numbers disable");

    setDisableLink("RoomGameNumbersHelp disable-link");

    const indexOfUser = thisRoomPlayerIds.indexOf(userId);

    const rawValue = e.target ? parseInt(e.target.id.slice(26)) : 0;

    const value = rawValue === 0 ? Math.floor(Math.random() * 9) + 1 : rawValue;

    const guessGameNumber = Math.floor(Math.random() * 9) + 1;

    // if you guess
    value === guessGameNumber ? setYouAreGuesed(true) : setYouAreGuesed(false);

    let myValue;

    const indexOfOasis = "oasis" + (indexOfPlayer + 1);

    const oasisNewHelps = { ...thisRoomWhosTurn.helpOasis };

    const gameTurns = {
      whosTurn: {
        gameStarted: true,
        currentNumber: 0,
        turnStart: differenceSeconds,
        gameOver: false,
        helpOasis: thisRoomWhosTurn.helpOasis,
        specialRandom: thisRoomWhosTurn.specialRandom,

        player1: {
          turn: false,
          restart: false,
        },

        player2: {
          turn: false,
          restart: false,
        },

        player3: {
          turn: false,
          restart: false,
        },

        player4: {
          turn: false,
          restart: false,
        },
      },
    };

    if (oasisHelpCondition === "plus") {
      myValue = value;

      if (value === guessGameNumber) {
        oasisNewHelps[indexOfOasis].matched += 1;
      }
    } else if (oasisHelpCondition === "double") {
      myValue = value * 2;

      if (value !== guessGameNumber) {
        if (oasisNewHelps[indexOfOasis].matched > 0) {
          oasisNewHelps[indexOfOasis].matched -= 1;
        } else {
          oasisNewHelps[indexOfOasis].double -= 1;
        }
      }
    } else if (oasisHelpCondition === "extra") {
      myValue = value + 7;

      if (value !== guessGameNumber) {
        if (oasisNewHelps[indexOfOasis].matched > 0) {
          oasisNewHelps[indexOfOasis].matched -= 1;
        } else {
          oasisNewHelps[indexOfOasis].extra -= 1;
        }
      }
    } else if (oasisHelpCondition === "minus") {
      myValue = -2 * value;

      if (value !== guessGameNumber) {
        if (oasisNewHelps[indexOfOasis].matched > 0) {
          oasisNewHelps[indexOfOasis].matched -= 1;
        } else {
          oasisNewHelps[indexOfOasis].minus -= 1;
        }
      }
    } else if (oasisHelpCondition === "added") {
      myValue = value + 4;

      if (value !== guessGameNumber) {
        if (oasisNewHelps[indexOfOasis].matched > 0) {
          oasisNewHelps[indexOfOasis].matched -= 1;
        } else {
          oasisNewHelps[indexOfOasis].added -= 1;
        }
      }
    } else if (oasisHelpCondition === "bonus") {
      myValue = value + 1;

      if (value !== guessGameNumber) {
        if (oasisNewHelps[indexOfOasis].matched > 0) {
          oasisNewHelps[indexOfOasis].matched -= 1;
        } else {
          oasisNewHelps[indexOfOasis].bonus -= 1;
        }
      }
    }

    if (exactLocation === "single-player") {
      if (thisRoomWhosTurn.player1.turn && indexOfUser === 0) {
        const continueGame = {
          ...gameTurns,

          whosTurn: {
            ...gameTurns.whosTurn,

            currentNumber: thisRoomWhosTurn.currentNumber + myValue,

            player2: {
              ...gameTurns.whosTurn.player1,
              turn: true,
            },
          },
        };

        editRoomAsync(roomId, continueGame);
        setYourTurnShowQuick(true);
        setMyDigit(myValue);

        setTimeout(() => {
          setMyDigit(null);
        }, 2400);
      } else if (thisRoomWhosTurn.player2.turn && indexOfUser === 1) {
        const continueGame = {
          ...gameTurns,

          whosTurn: {
            ...gameTurns.whosTurn,

            currentNumber: thisRoomWhosTurn.currentNumber + myValue,

            player1: {
              ...gameTurns.whosTurn.player1,
              turn: true,
            },
          },
        };

        editRoomAsync(roomId, continueGame);
        setYourTurnShowQuick(true);
        setMyDigit(myValue);

        setTimeout(() => {
          setMyDigit(null);
        }, 2400);
      }
    } else if (exactLocation === "multi-player") {
      if (thisRoomWhosTurn.player1.turn && indexOfUser === 0) {
        const continueGame = {
          ...gameTurns,

          whosTurn: {
            ...gameTurns.whosTurn,

            currentNumber: thisRoomWhosTurn.currentNumber + myValue,

            player2: {
              ...gameTurns.whosTurn.player1,
              turn: true,
            },
          },
        };

        editRoomAsync(roomId, continueGame);
        setYourTurnShowQuick(true);
        setMyDigit(myValue);

        setTimeout(() => {
          setMyDigit(null);
        }, 2400);
      } else if (thisRoomWhosTurn.player2.turn && indexOfUser === 1) {
        const continueGame = {
          ...gameTurns,

          whosTurn: {
            ...gameTurns.whosTurn,

            currentNumber: thisRoomWhosTurn.currentNumber + myValue,

            player3: {
              ...gameTurns.whosTurn.player1,
              turn: true,
            },
          },
        };

        editRoomAsync(roomId, continueGame);
        setYourTurnShowQuick(true);
        setMyDigit(myValue);

        setTimeout(() => {
          setMyDigit(null);
        }, 2400);
      } else if (thisRoomWhosTurn.player3.turn && indexOfUser === 2) {
        const continueGame = {
          ...gameTurns,

          whosTurn: {
            ...gameTurns.whosTurn,

            currentNumber: thisRoomWhosTurn.currentNumber + myValue,

            player4: {
              ...gameTurns.whosTurn.player1,
              turn: true,
            },
          },
        };

        editRoomAsync(roomId, continueGame);
        setYourTurnShowQuick(true);
        setMyDigit(myValue);

        setTimeout(() => {
          setMyDigit(null);
        }, 2400);
      } else if (thisRoomWhosTurn.player4.turn && indexOfUser === 3) {
        const continueGame = {
          ...gameTurns,

          whosTurn: {
            ...gameTurns.whosTurn,

            currentNumber: thisRoomWhosTurn.currentNumber + myValue,

            player1: {
              ...gameTurns.whosTurn.player1,
              turn: true,
            },
          },
        };

        editRoomAsync(roomId, continueGame);
        setYourTurnShowQuick(true);
        setMyDigit(myValue);

        setTimeout(() => {
          setMyDigit(null);
        }, 2400);
      }
    }

    // reset oasisSign
    isOasisHelpClickedAsync("plus");
    // reset oasisSign

    // reset CountDown
    isCountDownTimerZeroAsync(false);
    // reset CountDown
  };
  // clicked circle number

  return !hidden ? (
    <div className="RoomGameNumbers">
      {!hamburgerCondition && gameStarted ? (
        <BackGroundMusic
          Audiodata={[
            roomNumbersBackgroundSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <ExitRoomConfirm
        newRoomData={[
          roomNumber,
          exactLocation,
          roomId,
          userId,
          userName,
          thisRoomWhosTurn,
          thisRoomPlayerIds,
        ]}
      />

      {/* When game started hide */}
      {!gameStarted && !exitRoomCondition[0] ? (
        <WaitingForOtherPlayers
          newRoomData={[
            exactLocation,
            roomId,
            thisRoomPlayersCount,
            indexOfPlayer,
            thisRoomWhosTurn,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}
      {/* When game started hide */}

      {!exitRoomCondition[0] ? (
        <GameStatus whosTurnIs={thisRoomWhosTurn} />
      ) : null}

      {!exitRoomCondition[0] ? (
        <GameScore gameScoreData={[thisRoomWhosTurn, hidenGameScore]} />
      ) : null}

      {/* Features */}
      {!exitRoomCondition[0] && yourTurnShow ? (
        <div className={features}>
          <MileStones thisRoomWhosTurn={thisRoomWhosTurn} />
        </div>
      ) : null}
      {/* Features */}

      {/* Circle Numbers */}
      {!exitRoomCondition[0] ? (
        <div className={circle}>
          <div className="RoomGameNumbers-loading">
            {indexOfPlayer !== -1 && <LoaderCircleNumbers />}
          </div>

          {!exitRoomCondition[0] ? (
            <Digits roomData={[myDigit, hideDigits, youAreGuessed]} />
          ) : null}

          <div className={disableClick}>
            <hr className="RoomGameNumbersline" />
            <hr className="RoomGameNumbersline" />
            <hr className="RoomGameNumbersline" />
            <hr className="RoomGameNumbersline" />
            <hr className="RoomGameNumbersline" />

            <form className="circle-numbers-switch">
              <label htmlFor="RoomGameNumberslineswitch_0">
                <span>Random</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_1">
                <span>1</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_2">
                <span>2</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_3">
                <span>3</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_4">
                <span>4</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_5">
                <span>5</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_6">
                <span>6</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_7">
                <span>7</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_8">
                <span>8</span>
              </label>
              <label htmlFor="RoomGameNumberslineswitch_9">
                <span>9</span>
              </label>

              <input
                type="radio"
                defaultChecked
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_0"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_1"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_2"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_3"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_4"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_5"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_6"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_7"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_8"
                onClick={onNumberClick}
              />
              <input
                type="radio"
                name="RoomGameNumbersline"
                id="RoomGameNumberslineswitch_9"
                onClick={onNumberClick}
              />

              <div className="RoomGameNumbers-selected-light"></div>

              <div className="RoomGameNumbers-selected-dot">
                <span></span>
              </div>

              <div className="circle-numbers-center">
                <div className="circle-numbers-center-small"></div>
              </div>
            </form>
          </div>

          {!yourTurnShow && (
            <OpponentTurnPauseSecondLayer thisRoomWhosTurn={thisRoomWhosTurn} />
          )}

          {yourTurnShowQuick && <OpponentTurnPause />}
        </div>
      ) : null}
      {/* Circle Numbers */}

      {/* Help */}
      {!exitRoomCondition[0] && yourTurnShow ? (
        <div className={help}>
          <SupportOasis
            roomData={[disableLink, thisRoomWhosTurn, indexOfPlayer]}
          />
        </div>
      ) : null}
      {/* Help */}

      {!exitRoomCondition[0] ? (
        <CountdownTimer
          roomData={[
            thisRoom,
            thisRoomWhosTurn,
            hideCountDown,
            onNumberClick,
            roomId,
            exactLocation,
          ]}
        />
      ) : null}

      {/* Buttons */}
      <RoomGameSettingsAndExitBtns isExitRoomAsync={isExitRoomAsync} />
      {/* Buttons */}

      {!exitRoomCondition[0] ? (
        <Explanation thisRoomWhosTurn={thisRoomWhosTurn} />
      ) : null}
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomGameNumbers);
