import React, { Fragment, useEffect, useState } from "react";
import "./General.css";
import GuestCardArea from "../Guest/GuestCardArea";
import GuestBackHomeArea from "../Guest/GuestBackHomeArea";
import Mainimage from "../ImagePlace/MainImage";
import AllButtons from "../MenuButtons/AllButtons";
import GuestWaves from "../Guest/GuestWaves";
import usePageAccessType from "../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function General({ hamburgerCondition, showGuestCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  // Without this, direct access is not working properly
  useEffect(() => {}, [isPageRefreshed, isDirectAccess, isExternalNavigation]);
  // Without this, direct access is not working properly

  const [showGuest, setShowGuest] = useState("");

  // STYLE --- Screen size/height applies for GuestCardArea
  const [smallScreen, setSmallScreen] = useState();

  useEffect(() => {
    const checkHeight = () => {
      if (window.innerHeight > 670) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };

    // Initial check
    checkHeight();

    // Add an event listener to respond to changes in window height
    window.addEventListener("resize", checkHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);
  // STYLE --- Screen size/height applies for GuestCardArea

  useEffect(() => {
    showGuestCondition ? setShowGuest(true) : setShowGuest(false);
  }, [showGuestCondition]);

  const [isHidden, setIsHidden] = useState("General");

  //is clicked hamburger
  useEffect(() => {
    !hamburgerCondition ? setIsHidden("General") : setIsHidden("hidden");
  }, [hamburgerCondition]);
  //is clicked hamburger

  return (
    <main className={isHidden}>
      {showGuest ? (
        smallScreen ? (
          <Fragment>
            <GuestWaves /> <GuestCardArea />
          </Fragment>
        ) : (
          <Fragment>
            <GuestCardArea /> <GuestBackHomeArea />
          </Fragment>
        )
      ) : (
        <Fragment>
          <Mainimage /> <AllButtons />
        </Fragment>
      )}
    </main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(General);
