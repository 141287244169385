import React from "react";
import "./GuestArea.css";
import "./GuestAreaAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import GuestWaves from "./GuestWaves";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - GuestArea

function GuestBackHomeArea({ isClickedHamburgerAsync, isShowGuestAsync }) {
  const handleHome = () => {
    //play click
    ClickSound(menuButtonsSound);

    // close the menu when clicked home
    isClickedHamburgerAsync(false);

    // close guest area when clicked home
    isShowGuestAsync(false);
  };

  return (
    <div className="GuestBackHomeArea">
      <GuestWaves />
      <div className="GuestBack-btnsPlace">
        <button
          className="GuestBack-btnsPlace_btn"
          name="Main Page"
          title="Main Page"
          onClick={handleHome}
        >
          <div className="GuestBack-btnsPlace_btn_text">Main Page</div>
        </button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestBackHomeArea);
