import React from "react";

// parent component is - GuestArea

function GuestWaves() {
  return (
    <div className="GuestBack_loader" title="Riding The Waves">
      <svg
        version="1.1"
        x="10px"
        y="0px"
        width="400px"
        height="50px"
        viewBox="0 0 30 30"
      >
        <rect x="-20" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="0"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="-9" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="0.5s"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="2" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="1s"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="12" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="1.5s"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="22" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="2s"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="33" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="2.5s"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="44" y="0" width="6" height="10">
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0 0; 0 30; 0 0"
            begin="3s"
            dur="3s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  );
}

export default GuestWaves;
