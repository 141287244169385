import React, { useEffect, useState } from "react";
import "./MenuButtons.css";
import "./MenuButtonsAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import guestShowUpSound from "../../../Audio/guestShowUp.mp3";
import { Link, useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AllButtons

function SingleUserButton({
  user,
  isSingleUserAsync,
  isMultiPlayerAsync,
  isTournamentAsync,
  isWorldAsync,
  isContinentalAsync,
  isCountryAsync,
  isCityAsync,
  isEventAsync,
  isShowGuestAsync,
}) {
  const navigate = useNavigate();

  // user or guest
  const [userOrGuest, setuserOrGuest] = useState(false);

  // if user logged in navigate actual page
  const [navPage, setNavPage] = useState("/");

  useEffect(() => {
    if (user === undefined || user === null || user === "") {
      setuserOrGuest(false);

      setNavPage("/");
    } else {
      setuserOrGuest(true);

      setNavPage("/single-player");
    }
  }, [user]);
  // user or guest

  // clicked single player
  const handleClick = () => {
    isSingleUserAsync(true);
    isMultiPlayerAsync(false);
    isTournamentAsync(false);
    isWorldAsync(false);
    isContinentalAsync(false);
    isCountryAsync(false);
    isCityAsync(false);
    isEventAsync(false);

    if (!userOrGuest) {
      //play click
      ClickSound(guestShowUpSound);

      navigate("/");

      isShowGuestAsync(true);
    } else {
      //play click
      ClickSound(menuButtonsSound);

      navigate("/single-player");

      isShowGuestAsync(false);
    }
  };
  // clicked single player

  return (
    <div
      className="btn_single"
      title="Single Player | Blitz Games"
      onClick={handleClick}
    >
      <div className="btn_general">
        <Link to={navPage}>Single Player</Link>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleUserButton);
