import { bindActionCreators } from "redux";
import actions from "../Actions";

export const mapStateToProps = (state) => {
  const hamburgerState = state.hamburgerReducer;
  const navState = state.navReducer;
  const authState = state.authReducer;
  const showGuestState = state.showGuestReducer;
  const gameTypeState = state.gameTypeReducer;
  const logOutWindowState = state.logOutWindowReducer;
  const isLogoutCompletedState = state.isLogoutCompletedReducer;
  const isDeleteCompletedState = state.isDeleteCompletedReducer;
  const deleteWindowState = state.deleteWindowReducer;
  const avatarFeaturesState = state.avatarFeaturesReducer;
  const isEditTypeState = state.selectedEditTypeReducer;
  const isEditCompletedState = state.isCompletedEditReducer;
  const isRoomAvailableState = state.isRoomAvailableReducer;
  const isWatchingTheGameState = state.watchingTheGameReducer;
  const authRoomState = state.authRoomReducer;
  const exitRoomState = state.isExitRoomReducer;
  const getSingleRoomState = state.singleRoomReducer;
  const oasisHelpState = state.oasisReducer;
  const counDownState = state.countDownReducer;
  const hideRestartGameState = state.hideRestartReducer;
  const isForgetPasswordCompletedState = state.isForgetPasswordCompletedReducer;
  const authRewardState = state.authRewardReducer;
  const getSingleRewardState = state.singleRewardReducer;
  const mutedMusicState = state.mutedMusicReducer;

  return {
    hamburgerCondition: hamburgerState.isHamburgerOn,
    navCondition: navState.isNav,
    showGuestCondition: showGuestState.isShowGuest,

    authStateCondition: authState,

    singleCondition: gameTypeState.singlePlayer,
    multiPlayerCondition: gameTypeState.multiplayer,
    tournamentCondition: gameTypeState.tournaments.selected,
    selectedChampionships: gameTypeState.tournaments,
    worldCondition: gameTypeState.tournaments.worldCup,
    continentalCondition: gameTypeState.tournaments.continental.selected,
    countryCondition: gameTypeState.tournaments.country.selected,
    cityCondition: gameTypeState.tournaments.city.selected,
    eventCondition: gameTypeState.tournaments.event.selected,
    specifiedTourTypeCondition: gameTypeState.specifiedTourType,
    logOutWindowCondition: logOutWindowState.isLogOutWindowOn,
    deleteWindowCondition: deleteWindowState.isDeleteWindowOn,
    LogoutCompletedCondition: isLogoutCompletedState.isLogoutCompleted,
    DeleteCompletedCondition: isDeleteCompletedState.isDeleteCompleted,
    isEditTypeCondition: isEditTypeState.isSelectedEditOn,
    isEditCompletedCondition: isEditCompletedState.isEditComleted,
    avatarFeatureCondition: avatarFeaturesState.isFeaturesOn,
    isPlayerRoomAvailable: isRoomAvailableState.isRoomAvailable,
    isWatchingGameCondition: isWatchingTheGameState.isWatchingOn,
    authRoomStateCondition: authRoomState,
    exitRoomCondition: exitRoomState.exitRoom,
    getSingleRoomCondition: getSingleRoomState,
    oasisHelpCondition: oasisHelpState.isOasis,
    counDownCondition: counDownState.isZero,
    hideRestartGameCondition: hideRestartGameState.isHideRestart,
    isForgetPasswordCompletedCondition:
      isForgetPasswordCompletedState.isLogoutCompleted,
    authRewardStateCondition: authRewardState,
    getSingleRewardCondition: getSingleRewardState,
    mutedMusicCondition: mutedMusicState.isMusicOn,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};
