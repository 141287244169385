import { combineReducers } from "redux";
import hamburgerReducer from "./isClickedHamburger";
import navReducer from "./isClickedNav";
import logOutWindowReducer from "./isClickedLogoutWindow";
import isLogoutCompletedReducer from "./isLogoutCompleted";
import isDeleteCompletedReducer from "./isDeleteCompleted";
import deleteWindowReducer from "./isClickedDeleteWindow";
import selectedEditTypeReducer from "./isSelectedEditType";
import isCompletedEditReducer from "./isCompletedEdit";

import authReducer from "./userAuth";

import showGuestReducer from "./showGuestSection";
import isExitRoomReducer from "./exitRoom";
import gameTypeReducer from "./gameType";

import avatarFeaturesReducer from "./avatarFeatures";
import isRoomAvailableReducer from "./isPlayerRoomsAvailable";
import watchingTheGameReducer from "./isWatchingTheGame";
import authRoomReducer from "./roomAuth";

import singleRoomReducer from "./getSingleRoomData";
import oasisReducer from "./oasisHelp";
import countDownReducer from "./isCountDownZero";
import hideRestartReducer from "./hideRestartGame";
import isForgetPasswordCompletedReducer from "./forgetPasswordCompleted";

import authRewardReducer from "./rewardsAuth";
import singleRewardReducer from "./getSingleRewardData";
import mutedMusicReducer from "./isMutedMusic";

export default combineReducers({
  hamburgerReducer,
  navReducer,
  authReducer,
  gameTypeReducer,
  showGuestReducer,
  logOutWindowReducer,
  isLogoutCompletedReducer,
  isDeleteCompletedReducer,
  isForgetPasswordCompletedReducer,
  deleteWindowReducer,
  selectedEditTypeReducer,
  isCompletedEditReducer,
  avatarFeaturesReducer,
  isRoomAvailableReducer,
  watchingTheGameReducer,
  authRoomReducer,
  isExitRoomReducer,
  singleRoomReducer,
  oasisReducer,
  countDownReducer,
  hideRestartReducer,
  authRewardReducer,
  singleRewardReducer,
  mutedMusicReducer,
});
