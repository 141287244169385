import React, { useState, useEffect } from "react";
import "./RegistrationForm.css";
import "./RegistrationFormAnimation.css";
import "../Form.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import registerSound from "../../../Audio/createGame.mp3";
import RegistrationCompletedFeatures from "../Registration/RegistrationCompletedFeatures";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// parent component is - RegistrationPlace

function RegistrationForm({
  registerAsync,
  isShowGuestAsync,
  authStateCondition,
  createRewardAsync,
}) {
  const { user } = authStateCondition;

  const navigate = useNavigate();

  const [loggedInUser, setLoggedInUser] = useState(false);

  // if user has already logged in, make unclickable
  useEffect(() => {
    if (user !== undefined && user !== null) {
      setLoggedInUser(true);
    } else {
      setLoggedInUser(false);
    }
  }, [user]);
  // if user has already logged in, make unclickable

  // hide registration form, when already filled out form and pushed "sign up" button
  const [regiFinished, setRegiFinished] = useState("registration");

  const [hidden, setHidden] = useState("registration_content");

  // hide registration form, when already filled out form and pushed "sign up" button

  // Form error messages
  const [errForm, setErrForm] = useState({
    userName_err_display: "hidden",
    email_err_display: "hidden",
    password_err_display: "hidden",
    confirmedPassword_err_display: "hidden",
  });

  // Distracture Form error messages
  const {
    userName_err_display,
    email_err_display,
    password_err_display,
    confirmedPassword_err_display,
  } = errForm;
  // Form error messages

  // Form Data
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmedPassword: "",
  });

  // Distracture Form Data
  const { userName, email, password, confirmedPassword } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  // Get Form Values

  const handleRegister = async (
    userName,
    email,
    password,
    confirmedPassword,
  ) => {
    const userData = {
      name: userName,
      email: email,
      password: password,
      confirmedPassword: confirmedPassword,
    };

    try {
      const authSuccess = await registerAsync(userData);

      if (authSuccess) {
        // Create user reward
        const userReward = {
          playerId: authSuccess._id,
          playerName: authSuccess.name,
        };

        // Await createRewardAsync to ensure it completes before continuing
        await createRewardAsync(userReward);

        isShowGuestAsync(false);

        // Proceed with further actions after reward creation
        console.log("Reward creation successful, proceed with other actions.");
      } else {
        // Handle the case where authSuccess is false
        console.log("Registration failed or authSuccess is false.");

        navigate(0);
      }
    } catch (error) {
      // Handle any errors during registration or reward creation
      console.error("Error during registration or reward creation:", error);

      navigate(0);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //play click
    ClickSound(registerSound);

    // validate username, email and password fields
    const validSymbols = /^[a-zA-Z0-9]+$/;
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // validate username, email and password fields

    // temporary errors in a function, at the end of function assign to useState
    const tempErrForm = {
      ...errForm,
    };
    // temporary errors in a function, at the end of function assign to useState

    // temporary user data, check if all fields are good to send to back side
    const newUser = {
      newUserName: "",
      newEmail: "",
      newPassword: "",
      newConfirmedPassword: "",
    };
    // temporary user data, check if all fields are good to send to back side

    // username validation //registration
    if (
      userName.length < 4 ||
      userName.length > 16 ||
      !userName.match(validSymbols)
    ) {
      Object.assign(tempErrForm, {
        userName_err_display: "userName_err_display",
      });

      Object.assign(newUser, {
        newUserName: "",
      });
    } else {
      Object.assign(tempErrForm, {
        userName_err_display: "hidden",
      });

      Object.assign(newUser, {
        newUserName: userName,
      });
    }
    // username validation //registration

    // email validation //registration
    if (email.length < 6 || email.length > 32 || !email.match(validEmail)) {
      Object.assign(tempErrForm, {
        email_err_display: "email_err_display",
      });

      Object.assign(newUser, {
        newEmail: "",
      });
    } else {
      Object.assign(tempErrForm, {
        email_err_display: "hidden",
      });

      Object.assign(newUser, {
        newEmail: email,
      });
    }
    // email validation //registration

    // password validation //registration
    if (
      password.length < 6 ||
      password.length > 16 ||
      !password.match(validSymbols)
    ) {
      Object.assign(tempErrForm, {
        password_err_display: "password_err_display",
      });

      Object.assign(newUser, {
        newPassword: "",
      });
    } else {
      Object.assign(tempErrForm, {
        password_err_display: "hidden",
      });

      Object.assign(newUser, {
        newPassword: password,
      });
    }
    // password validation //registration

    // confirmed Password validation //registration
    if (confirmedPassword !== password) {
      Object.assign(tempErrForm, {
        confirmedPassword_err_display: "confirmedPassword_err_display",
      });
      Object.assign(newUser, {
        newConfirmedPassword: "",
      });
    } else {
      Object.assign(tempErrForm, {
        confirmedPassword_err_display: "hidden",
      });

      Object.assign(newUser, {
        newConfirmedPassword: confirmedPassword,
      });
    }
    // confirmed Password validation //registration

    // errors send to usestate to control classes //registration
    setErrForm(tempErrForm);
    // errors send to usestate to control classes //registration

    // temporary user object values, //registration
    const checkUserName = Object.values(newUser)[0];
    const checkEmail = Object.values(newUser)[1];
    const checkPassword = Object.values(newUser)[2];
    const checkConfirmedPassword = Object.values(newUser)[3];
    // temporary user object values, //registration

    // temporary user object values, to check if all fields are ok to send to back side while registration
    if (
      checkUserName === "" ||
      checkEmail === "" ||
      checkPassword === "" ||
      checkConfirmedPassword === ""
    ) {
      console.log("Failed");
    } else {
      if (user === null && !loggedInUser) {
        handleRegister(
          checkUserName,
          checkEmail,
          checkPassword,
          checkConfirmedPassword,
        );

        setRegiFinished("registration_finished");

        setHidden("hidden");
      } else {
        navigate("/profile");
      }
    }
    // temporary user object values, to check if all fields are ok to send to back side while registration
  };

  // handle Sign in click, just for sound
  const handleSignIn = () => {
    //play click
    ClickSound(popUpSound);
  };
  // handle Sign in click, just for sound

  return (
    <section className={regiFinished}>
      <RegistrationCompletedFeatures />

      <div className={hidden}>
        <h2>Registration</h2>
        <div className="form-err">
          {/* error messages */}
          <div className={userName_err_display}>
            <p>
              !!! Please enter a username between 4 and 16 characters. Use only
              letters and numbers. Symbols are not allowed.
            </p>
          </div>
          <div className={email_err_display}>
            <p>!!! Please enter a valid email address.</p>
          </div>
          <div className={password_err_display}>
            <p>
              !!! The password should be between 6 and 16 characters long.
              Numbers and letters should be used only. Symbols are not allowed.
            </p>
          </div>
          <div className={confirmedPassword_err_display}>
            <p>!!! The passwords do not match.</p>
          </div>
          {/* error messages */}
        </div>
        {/*Form */}
        <form
          className={!loggedInUser ? "form" : "form disable-link"}
          onSubmit={onSubmit}
          id="signup-form"
          name="signup"
        >
          <div className="inputBox">
            <input
              type="text"
              name="userName"
              id="registration-userName"
              value={userName}
              onChange={onChange}
              required
              maxLength="16"
              pattern="[a-zA-Z0-9]*"
              title="The username must be between 4 and 16 letters long and can only contain letters and numbers."
              autoFocus
              autoComplete="username"
            />
            <i>Username</i>
          </div>
          <div className="inputBox">
            <input
              type="email"
              name="email"
              id="registration-email"
              value={email}
              onChange={onChange}
              required
              maxLength="32"
              title="The Email should be between 6 and 32 characters long."
              autoComplete="email"
            />
            <i>E-mail</i>
          </div>
          <div className="inputBox">
            <input
              type="password"
              name="password"
              id="registration-password"
              value={password}
              onChange={onChange}
              required
              maxLength="16"
              title="The password must be between 6 and 16 letters long and can only contain letters and numbers."
              autoComplete="off"
            />
            <i>Password</i>
          </div>
          <div className="inputBox">
            <input
              type="password"
              name="confirmedPassword"
              id="registration-confirmedPassword"
              value={confirmedPassword}
              onChange={onChange}
              required
              maxLength="16"
              title="Re-type Password"
              autoComplete="off"
            />
            <i>Confirm Password</i>
          </div>
          <div
            className="inputBox"
            title="If everything checks out, just log in!"
          >
            <button className="submit_BTN" type="submit">
              Sign up
            </button>
          </div>
          <div
            className="input_links"
            title="Join now if you don't have an account!"
          >
            <p>Already have an account?</p>
            <Link to="/login" onClick={handleSignIn}>
              Sign in
            </Link>
          </div>
        </form>
        {/*Form */}
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
