import React, { useEffect, useState } from "react";
import "./CreateRoom.css";
import "./CreateRoomAnimation.css";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import createGameSound from "../../../../Audio/createGame.mp3";
import popUpSound from "../../../../Audio/popUp.mp3";
import RedirectToCreatedRoom from "../RedirectToCreatedRoom/RedirectToCreatedRoom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - CreateRoomContainer

function CreateRoom({
  roomData,
  createRoomAsync,
  authRoomStateCondition,
  resetRoomsAsync,
  resetRoomAsync,
}) {
  const [userId, userName, exactLocation] = roomData;

  const [createdUserId, setCreatedUserId] = useState(undefined);

  const [loadStatus, setLoadingOrReady] = useState(undefined);

  const [unAblaToClick, setUnAbleToClick] = useState(false);

  const { room, isLoading, isError, isSuccess, message } =
    authRoomStateCondition;

  useEffect(() => {
    if (isLoading) {
      setLoadingOrReady("loading");
    }
    if (isError) {
      setLoadingOrReady("error");
    }

    if (isSuccess) {
      setLoadingOrReady("success");
    }
    if (message === "Request failed with status code 400") {
      setLoadingOrReady("404");
    }

    return resetRoomsAsync();
  }, [resetRoomsAsync, isLoading, isError, isSuccess, message]);

  // show passcode field
  const [showPasscode, setShowPasscode] = useState("hidden");
  // show passcode field

  // show error, if passcode is wrong
  const [showErr, setShowErr] = useState("hidden");
  // show error, if passcode is wrong

  // Form Data
  const [formData, setFormData] = useState({
    gameType:
      exactLocation === "single-player"
        ? "CreateRoom-single-player"
        : "CreateRoom-multi-player",
    gameDuration: "duration-14",
    selectPassCode: "",
  });

  // Distracture Form Data
  const { gameType, gameDuration, selectPassCode } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    // play click for input fields
    ClickSound(popUpSound);

    if (e.target.name === "selectPassCode") {
      setFormData((previousState) => ({
        ...previousState,
        [e.target.name]: e.target.value,
      }));
    } else {
      setFormData((previousState) => ({
        ...previousState,
        [e.target.name]: e.target.id,
      }));
    }
  };
  // Get Form Values

  // Show passcode or not
  const handlePasscode = (e) => {
    if (e.target.id === "withPassCode") {
      setShowPasscode("CreateRoom-button-container");
    } else {
      setShowPasscode("hidden");

      setShowErr("hidden");

      setFormData({
        ...formData,
        selectPassCode: "",
      });
    }
  };
  // Show passcode or not

  const handleCreateRoom = async (
    gameType,
    createdBy,
    gameDuration,
    selectPassCode,
  ) => {
    const roomData = {
      gameType,
      createdBy,
      duration: gameDuration,
      passCode: selectPassCode,
    };

    const authSuccess = await createRoomAsync(roomData);

    if (authSuccess) {
      setCreatedUserId(createdBy.id);
    }

    // create game sound
    ClickSound(createGameSound);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    resetRoomAsync();

    setUnAbleToClick(true);

    setTimeout(() => {
      setUnAbleToClick(false);
    }, 15000);

    const validSymbols = /^[0-9]*$/;
    const duration = parseInt(gameDuration.slice(9));
    const createdBy = {
      id: userId,
      name: userName,
    };

    // temporary room data, check if all fields are good to send to back side
    const newRoom = {
      newGameType: "",
      newCreatedBy: "",
      newDuration: "",
      newPasscode: "",
    };
    // temporary room data, check if all fields are good to send to back side

    // gameType validation // create
    if (
      gameType === "CreateRoom-single-player" ||
      gameType === "CreateRoom-multi-player"
    ) {
      const roomType = gameType.slice(11);

      Object.assign(newRoom, {
        newGameType: roomType,
      });
    } else {
      Object.assign(newRoom, {
        newGameType: "",
      });
    }
    // gameType validation // create

    // gameCreated by validation // create
    Object.assign(newRoom, {
      newCreatedBy: createdBy,
    });
    // gameCreated by validation // create

    // gameDuration validation // create
    if (typeof duration === "number") {
      Object.assign(newRoom, {
        newDuration: duration,
      });
    } else {
      Object.assign(newRoom, {
        newDuration: "",
      });
    }
    // gameDuration validation // create

    // passcode validation // create
    if (
      selectPassCode === "" ||
      (selectPassCode.length <= 4 && selectPassCode.match(validSymbols))
    ) {
      setShowErr("hidden");

      // make gameDuration to number

      Object.assign(newRoom, {
        newPasscode: selectPassCode,
      });
    } else {
      setShowErr("passCodeErr");

      Object.assign(newRoom, {
        newPasscode: undefined,
      });
    }
    // passcode validation // create

    // temporary user object values, //registration
    const checkGameType = Object.values(newRoom)[0];
    const checkCreatedBy = Object.values(newRoom)[1];
    const checkDuration = Object.values(newRoom)[2];
    const checkPasscode = Object.values(newRoom)[3];
    // temporary user object values, //registration

    if (
      checkGameType === "" ||
      checkCreatedBy === "" ||
      checkDuration === "" ||
      checkPasscode === undefined
    ) {
      console.log("Failed");
    } else {
      handleCreateRoom(
        checkGameType,
        checkCreatedBy,
        checkDuration,
        checkPasscode,
      );
    }
  };

  return (
    <div className="CreateRoomFormContainer">
      <RedirectToCreatedRoom roomData={[createdUserId, loadStatus, room]} />

      <form className="CreateRoom" onSubmit={onSubmit}>
        <div className="CreateRoom-button-container">
          <h3>Select Game Type</h3>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="CreateRoom-single-player"
              name="gameType"
              value={gameType}
              onChange={onChange}
              defaultChecked={exactLocation === "single-player"}
            />
            <label
              className="CreateRoom-button__label"
              htmlFor="CreateRoom-single-player"
            >
              <span className="CreateRoom-button__custom"></span>
              Single Player
            </label>
          </div>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="CreateRoom-multi-player"
              name="gameType"
              value={gameType}
              onChange={onChange}
              defaultChecked={exactLocation === "multi-player"}
            />
            <label
              className="CreateRoom-button__label"
              htmlFor="CreateRoom-multi-player"
            >
              <span className="CreateRoom-button__custom"></span>
              Multiplayer
            </label>
          </div>
        </div>
        <div className="CreateRoom-button-container">
          <h3>Select Game Round Duration</h3>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="duration-14"
              name="gameDuration"
              value={gameDuration}
              onChange={onChange}
              defaultChecked
            />
            <label className="CreateRoom-button__label" htmlFor="duration-14">
              <span className="CreateRoom-button__custom"></span>
              14 sec.
            </label>
          </div>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="duration-32"
              name="gameDuration"
              value={gameDuration}
              onChange={onChange}
            />
            <label className="CreateRoom-button__label" htmlFor="duration-32">
              <span className="CreateRoom-button__custom"></span>
              32 sec.
            </label>
          </div>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="duration-60"
              name="gameDuration"
              value={gameDuration}
              onChange={onChange}
            />
            <label className="CreateRoom-button__label" htmlFor="duration-60">
              <span className="CreateRoom-button__custom"></span>
              60 sec.
            </label>
          </div>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="duration-300"
              name="gameDuration"
              value={gameDuration}
              onChange={onChange}
            />
            <label className="CreateRoom-button__label" htmlFor="duration-300">
              <span className="CreateRoom-button__custom"></span>5 min.
            </label>
          </div>
        </div>
        <div className="CreateRoom-button-container">
          <h3>If you want set a Passcode</h3>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="noPassCode"
              name="passCode"
              onChange={handlePasscode}
              defaultChecked
            />
            <label className="CreateRoom-button__label" htmlFor="noPassCode">
              <span className="CreateRoom-button__custom"></span>
              No Passcode
            </label>
          </div>
          <div className="CreateRoom-button">
            <input
              type="radio"
              className="CreateRoom-button__input"
              id="withPassCode"
              name="passCode"
              onChange={handlePasscode}
            />
            <label className="CreateRoom-button__label" htmlFor="withPassCode">
              <span className="CreateRoom-button__custom"></span>
              Set Passcode
            </label>
          </div>
        </div>
        <div className="CreateRoom-button-container-passcode">
          <div className={showPasscode}>
            <h3>Choose a Passcode ( Only numbers, 1 - 4 digits )</h3>
            <h3 className={showErr}>
              Please, select only numbers or letters, 1 - 4 digits
            </h3>
            <input
              type="text"
              className="CreateRoom-button__input_passcode"
              name="selectPassCode"
              value={selectPassCode}
              onChange={onChange}
              maxLength="4"
              placeholder="xxxx"
              autoFocus
            />
          </div>
        </div>
        <div className="inputBox" title="Create a room">
          {!unAblaToClick ? (
            <button className="submit_BTN" type="submit">
              Create a room
            </button>
          ) : (
            <button className="submit_BTN_NoneClick" type="submit">
              Creating a room
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoom);
